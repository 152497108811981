import { types, Instance, flow, applySnapshot, getParent } from "mobx-state-tree";
import { uuidCreate } from "utils/utils";
import { AddressModel } from "./models";
import { RootInstance } from "./RootStore";
import apiService from "api/api.service";

interface IProductPromotion {
  id: number;
  title: string;
  productId: number;
}

interface IProductsResponse {
  products: Product[];
  hideFromMarketer: number[];
  hideFromClient: number[];
  promos: IProductPromotion[]; // You can replace 'any' with a more specific type if needed
}

export const OrderModel = types.model("OrderModel", {
  couponUsed: 0, //vp
  creditUsed: 0, //nis
  shovarUsed: 0,
  fundingId: 0, //1-cc, 2-bank, 3-free
  shovarCode: types.maybeNull(types.string),
  orderId: types.maybeNull(types.number),
  checkoutStep: 1,
  address: AddressModel,
  isAddressDefault: false,
  correlationId: "",
});

export const OrderParamsModel = types.model("OrderModel", {
  items: "", //vp
  orderToPay: 0, //nis
  fundingId: 0, //1-cc, 2-bank, 3-free
  correlationId: "",
});

export const ProductModel = types.model("ProductModel", {
  id: types.identifierNumber,
  name: "",
  nameHtml: "",
  vp: 0,
  priceNoVp: 0,
  sellPrice: types.number,
  summary: "",
  summaryHtml: "",
  isVirtual: false,
  shopOrder: types.maybeNull(types.number),
  metaName: types.maybeNull(types.string),
  metaValue: types.maybeNull(types.string),
  promoTitle: types.maybeNull(types.string),
  promoId: types.maybeNull(types.number),
});

export const CartItemModel = types.model({
  product: types.reference(ProductModel),
  qty: types.number,
});

const FilterModel = types.model("FilterModel", {
  hideFromMarketer: types.optional(types.array(types.number), []),
  hideFromClient: types.optional(types.array(types.number), []),
});

export const ShopStoreInitialState = {
  order: { address: {} },
  filter: {},
  orderParams: {},
};

export const ShopStore = types
  .model({
    order: OrderModel,
    orderParams: OrderParamsModel,
    products: types.array(ProductModel),
    filter: FilterModel,
    cart: types.array(CartItemModel),
    cartIsOpen: false,
    error: "",
  })
  .views((store) => ({
    get productsCount() {
      return store.products.length;
    },
    get cartItemsCount() {
      return store.cart.reduce((acc, item) => acc + item.qty, 0);
    },
    get isCartEmpty() {
      return store.cart.length === 0;
    },
    get cartPrice() {
      return store.cart.reduce((sum, item) => sum + item.product.sellPrice * item.qty, 0);
    },
    get cartPriceNoVp() {
      return store.cart.reduce((sum, item) => sum + item.product.priceNoVp * item.qty, 0);
    },
    get isCartVirtual() {
      return store.cart.reduce((sum, item) => sum && item.product.isVirtual, true);
    },
    get cartVp() {
      return store.cart.reduce((vp, item) => vp + item.product.vp * item.qty, 0);
    },
    isInCart(id: number) {
      return store.cart.some((item) => item.product.id === id);
    },
    getQty(id: number) {
      const cartItem = store.cart.find((item) => item.product.id === id);
      return cartItem ? cartItem.qty : 0;
    },
  }))
  .views((store) => ({
    get fullName(): string {
      return store.order.address.firstName + " " + store.order.address.lastName;
    },
    get orderVp(): number {
      return store.cartVp - store.order.couponUsed;
    },
    get couponUsedValue(): number {
      const userStore = (getParent(store) as RootInstance).user;
      return store.order.couponUsed * (getParent(store) as RootInstance).settings.vpToAmount * userStore.afterDiscountMulti;
    },
    get isFreeShipment(): boolean {
      return store.cartPrice > 350 || store.isCartVirtual;
    },
    get shippingFee(): number {
      const settingsStore = (getParent(store) as RootInstance).settings;
      return store.cartPrice >= settingsStore.shippingThreshold || store.isCartVirtual ? 0 : settingsStore.shippingFee;
    },
  }))
  .views((store) => ({
    get creditGain(): number {
      const settings = (getParent(store) as RootInstance).settings;
      return store.orderVp * settings.creditGainMultiplier * settings.vpRate;
    },
    get balanceUsed(): number {
      return store.couponUsedValue + store.order.creditUsed;
    },
    get discountUsed(): number {
      const root = getParent(store) as RootInstance;
      return root.user.discountMulti * store.cartVp;
    },
  }))
  .views((store) => ({
    get orderToPay(): number {
      return Math.round(
        store.cartPrice -
          store.couponUsedValue -
          store.order.creditUsed -
          store.order.shovarUsed +
          store.shippingFee -
          store.discountUsed
      );
    },
  }))
  .actions((store) => ({
    reset() {
      applySnapshot(store, ShopStoreInitialState);
    },
    addToCart(id: number, qty: number) {
      const existingItemIndex = store.cart.findIndex((item) => item.product.id === id);
      if (existingItemIndex > -1) {
        store.cart[existingItemIndex].qty += qty;
      } else {
        store.cart.push({ product: id, qty: qty });
      }
    },
    removeFromCart(id: number, qty: number) {
      const idx = store.cart.findIndex((item) => item.product.id === id);
      if (idx > -1) {
        const newQuantity = store.cart[idx].qty - qty;
        if (newQuantity > 0) {
          store.cart[idx].qty = newQuantity;
        } else {
          store.cart.splice(idx, 1);
        }
      }
    },
    clearItemFromCart(id: number) {
      const idx = store.cart.findIndex((item) => item.product.id === id);
      if (idx > -1) {
        store.cart.splice(idx, 1);
      }
    },
    clearCart() {
      store.cart.clear();
    },
    openCart() {
      store.cartIsOpen = true;
    },
    closeCart() {
      store.cartIsOpen = false;
    },
    getProducts: flow(function* getProducts() {
      try {
        const response: IProductsResponse = yield apiService.get<IProductsResponse>("products");
        const shopProducts = response.products;
        const numOfProducts = shopProducts.length;
        const sortedProducts = shopProducts.sort((a, b) => {
          const aHasPromo = !!a.promoId && a.promoId > 0;
          const bHasPromo = !!b.promoId && b.promoId > 0;
          const aOrder = !!a.shopOrder ? a.shopOrder : numOfProducts;
          const bOrder = !!b.shopOrder ? b.shopOrder : numOfProducts;
          if (aHasPromo && bHasPromo) return aOrder - bOrder;
          if (aHasPromo) return -1;
          if (bHasPromo) return 1;
          return aOrder - bOrder;
        });
        applySnapshot(store.products, sortedProducts);
        const filter = {
          hideFromMarketer: response.hideFromMarketer,
          hideFromClient: response.hideFromClient,
        };
        applySnapshot(store.filter, filter);
      } catch (error) {
        console.log(error);
      }
    }),
  }))
  .actions((store) => ({
    setError: (error: string) => {
      store.error = error;
    },
    setCheckoutStep(step: number) {
      if (step === 0 || store.order.checkoutStep < 0) store.order.checkoutStep = 0;
      else store.order.checkoutStep = store.order.checkoutStep + step;
    },
    resetCheckout() {
      store.order.checkoutStep = 0;
      store.order.couponUsed = 0;
      store.order.creditUsed = 0;
      store.order.isAddressDefault = false;
      store.error = "";
      if (store.cartPrice - store.discountUsed < store.order.shovarUsed) {
        store.order.shovarUsed = 0;
      }
    },
    setAddress(address: IShippingAddress) {
      store.order.address = address;
    },
    setAddressDefault(value: boolean) {
      store.order.isAddressDefault = value;
    },
    setOrderId(id: number) {
      store.order.orderId = id;
    },
    setFundingId(id: number) {
      store.order.fundingId = id;
    },
    setBalanceUsed(price?: number, vp?: number) {
      if (store.cartPrice === 0) {
        store.order.couponUsed = 0;
        store.order.creditUsed = 0;
        store.order.shovarUsed = 0;
        return;
      }

      const user = (getParent(store) as RootInstance).user;
      const vpToAmount = (getParent(store) as RootInstance).settings.vpToAmount;

      if (store.order.shovarUsed > store.cartPrice) store.order.shovarUsed = 0;

      let amountToPay = store.cartPrice - store.order.shovarUsed;

      if (!user.isMarketer) store.order.creditUsed = Math.min(amountToPay, user.credit);

      amountToPay = amountToPay - store.order.creditUsed;

      if (user.coupon === 0 || amountToPay === 0) return;

      store.order.couponUsed = Math.min(store.cartVp, amountToPay / vpToAmount, user.coupon);
    },

    setCouponUsed(coupon: number) {
      store.order.couponUsed = coupon;
    },
    resetBalanceUsed() {
      store.order.couponUsed = 0;
      store.order.creditUsed = 0;
    },
  }))
  .actions((store) => ({
    afterCreate() {
      store.getProducts();
    },
    createOrder: flow(function* createOrder() {
      store.order.correlationId = uuidCreate();
      store.setError("");
      try {
        const params = {
          couponUsed: store.order.couponUsed,
          creditUsed: store.order.creditUsed,
          shovarUsed: store.order.shovarUsed,
          shovarCode: store.order.shovarUsed === 0 ? "" : store.order.shovarCode,
          items: Array.from(store.cart, (item) => {
            return {
              id: item.product.id,
              qty: item.qty,
              name: item.product.name,
              price: item.product.sellPrice,
              vp: item.product.vp,
            };
          }),
          orderToPay: store.orderToPay,
          orderVp: store.orderVp,
          shippingFee: store.shippingFee,
          shippingAddress: store.order.address,
          isAddressDefault: store.order.isAddressDefault,
          fundingId: store.order.fundingId,
          correlationId: store.order.correlationId,
        };
        store.orderParams.orderToPay = store.orderToPay;
        const data = yield apiService.post<any>("shop/order", params);
        store.order.orderId = data.orderId;
        store.order.shovarUsed = 0;
        store.order.shovarCode = "";
        if (data.orderId === 0) {
          store.setError(data.error || "שגיא: ההזמנה לא נוצרה");
          console.log(data);
          return;
        }

        if (data.fundingId === 1) {
          window.location.href = data.paymentUrl;
        } else {
          store.clearCart();
          (getParent(store) as RootInstance).user.afterCreate();
          store.setCheckoutStep(1);
          return;
        }
      } catch (error) {
        console.log(error);
        store.setError("קרתה שגיאה ביצירת הזמנה - פנה לתמיכה או נסה שנית");
      }
    }),
    validateShovar(apply: IShovarApplyResponse) {
      if ((getParent(store) as RootInstance).user.isMarketer) {
        store.order.shovarUsed = 0;
        store.order.shovarCode = "";
        return;
      }
      const amount = apply.amount;
      store.order.shovarCode = apply.code;
      if (amount > store.cartPrice) {
        store.order.shovarUsed = 0;
        return;
      }

      let needRecalculateBalance = false;
      if (store.orderToPay - amount < 0) needRecalculateBalance = true;
      store.order.shovarUsed = amount;
      if (needRecalculateBalance) store.setBalanceUsed();
    },
    resetShovar() {
      store.order.shovarUsed = 0;
      store.order.shovarCode = "";
      store.setBalanceUsed();
    },
  }));

export interface ShopType extends Instance<typeof ShopStore> {}

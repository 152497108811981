import { useEffect, Fragment } from "react";
import { useMst } from "stores/RootStore";
import AddressForm from "./AddressForm";
import ReviewOrder from "./ReviewOrder";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import { Step, StepLabel } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

const steps = ["כתובת למשלוח", "אישור הזמנה ותשלום"];

function Checkout(): React.ReactElement {
  const { shop, user } = useMst();
  const navigate = useNavigate();

  useEffect(() => {
    shop.resetCheckout();
    user.afterCreate();
    console.log("enter checkout");
  }, []);

  useEffect(() => {
    if (!user.isAuthenticated) navigate("/");
    if (shop.order.checkoutStep === steps.length) navigate("/shop/done");
    // eslint-disable-next-line
  }, [user.isAuthenticated, shop.order.checkoutStep]);

  const isHorisontal = useMediaQuery("(min-width:600px)");

  return (
    <Fragment>
      <Box
        sx={{
          width: "auto",
          "@media (min-width: 632px)": {
            width: 600,
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <Paper
          elevation={3}
          sx={{
            marginTop: 3,
            marginBottom: 3,
            padding: 2,
            "@media (min-width: 632px)": {
              marginTop: 6,
              marginBottom: 6,
              padding: 3,
            },
          }}
        >
          <Typography component="h1" variant="h4" align="center">
            קופה
          </Typography>
          {shop.isCartEmpty ? (
            <Fragment>
              <Box>
                <Typography variant="h4" color="initial">
                  עגלת הקניות ריקה - יש לבחור לפחות מוצר אחד!
                </Typography>
                <Link to="/shop">חזרה לחנות...</Link>
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <Stepper
                activeStep={shop.order.checkoutStep}
                sx={{ padding: "3,0,5" }}
                orientation={isHorisontal ? "horizontal" : "vertical"}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {shop.order.checkoutStep === 0 && <AddressForm />}
              {shop.order.checkoutStep === 1 && <ReviewOrder />}
            </Fragment>
          )}
        </Paper>
      </Box>
    </Fragment>
  );
}

export default observer(Checkout);

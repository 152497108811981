//import { products } from "mock/products.mock";
import { useEffect, useState } from "react";
import PageTitle from "components/PageTitle";
import { Alert, Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import CartPopUp from "components/Cart/CartPopup";
import { useMst } from "stores/RootStore";
import ProductCard from "components/Products/ProductCard";
import { observer } from "mobx-react-lite";
import ProductDetailsPopUp from "./Product/ProductDetailsPopUp";
import PromoBanner from "components/Products/PromoBanner";

interface Props {}

const ShopPage = (props: Props) => {
  const { user, shop } = useMst();
  const [productId, setProductId] = useState(0);
  const isPromoEnabled = true;

  useEffect(() => {
    if (shop.products.length === 0) shop.getProducts();
  }, [shop, shop.products.length]);

  const filterProduct = (id: number) => {
    if (user.isAuthenticated && user.isMarketer) {
      return !shop.filter.hideFromMarketer.includes(id);
    }
    return !shop.filter.hideFromClient.includes(id);
  };

  const onCloseProductDetails = () => {
    setProductId(0);
  };

  const showProductDetails = (e: React.MouseEvent<HTMLElement>, id: number) => {
    setProductId(id);
  };

  return (
    <div>
      <PageTitle>החברים שלך לאורח חיים בריא</PageTitle>
      <Container sx={{ py: { xs: 1, sm: 8 } }} maxWidth="lg">
        <Typography
          variant="h4"
          color="primary"
          fontSize={{
            lg: 28,
            md: 20,
            sm: 16,
            xs: 16,
          }}
          fontWeight="700"
          sx={{
            pt: 2,
            mb: 3,
            textAlign: "center",
            paddingInlineStart: "10px",
            paddingInlineEnd: "10px",
            marginInline: "auto",
            fontFamily: "Heebo",
          }}
        >
           {/* <Box sx={{marginBottom:"10px"}}>
              <Alert severity="info">
                <span>במוצרים שלנו אין רכיב חמץ אך המפעל לא עבר הכשר לפסח</span>
              </Alert>
            </Box> */}
          {user.isMarketerExpiredSoon && (
            <Box>
              <Alert severity="error">
                <span>שים לב החברות באתר מסתיימת בקרוב, תוכל לחדש באתר המשרד האחורי </span>

                <Button component={Link} href="https://office.holdon.co.il" target="_blank">
                  {" "}
                  לחידוש מנוי ...
                </Button>
              </Alert>
            </Box>
          )}
          {isPromoEnabled && (
           <PromoBanner isMarketer={user.isMarketer} />
          )}
         {!isPromoEnabled && ( <>
            <div>כשיש סביבנו כל כך הרבה "אויבים"</div>
            <div>אנחנו צריכים חברים שיעזרו לנו לנהל אורח חיים בריא, בקלות ובהנאה</div>
          </>)}
        </Typography>
        {/* <Alert severity="info">פסח שמח: שימו לב - במוצרים אין רכיב חמץ אך יחד עם זאת קו היצור לא עבר תהליך של הכשרה לפסח.</Alert> */}
        <Typography
          variant="h6"
          color="secondary"
          fontWeight="700"
          sx={{
            pt: 2,
            mb: 3,
            textAlign: "center",
            paddingInlineStart: "10px",
            paddingInlineEnd: "10px",
            marginInline: "auto",
            maxWidth: "sm",
            fontFamily: "Heebo",
          }}
        >
          {!isPromoEnabled && (
          <span>*שים לב! - קניה במארזים משתלמת יותר מקניה בבודדים</span>
            )}
            {/* {isPromoEnabled && (
              <span>המבצע מסתיים ב 31 לינואר</span>
            )

            } */}

        </Typography>
        <Grid container spacing={4}>
          {shop.products
            .filter((p) => filterProduct(p.id))
            .map((product) => (
              <ProductCard product={product} key={product.id} showProductDetails={showProductDetails} />
            ))}
        </Grid>
      </Container>
      <CartPopUp />
      {!!productId && <ProductDetailsPopUp id={productId} onClose={onCloseProductDetails} />}
    </div>
  );
};

export default observer(ShopPage);

// const ProductsRow = styled("div")`
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: 25px;
//   background-color: "#f7f7f7";
//   position: relative;
//   z-index: 1;
//   margin: 0 -15px;
//   @media (max-width: 990px) {
//     margin-left: 0px;
//     margin-right: 0px;
//     margin-top: 0;
//     background-color: "#ffffff";
//   }
// `;
// const ProductsCol = styled("div")`
//   flex: 0 0 33.333%;
//   max-width: 33.3333333%;
//   padding-left: 15px;
//   padding-right: 15px;
//   margin-bottom: 30px;
//   @media (min-width: 768px) and (max-width: 1300px) {
//     flex: 0 0 33.3333333%;
//     max-width: 33.3333333%;
//   }
//   @media (max-width: 1199px) and (min-width: 991px) {
//     padding-left: 10px;
//     padding-right: 10px;
//     margin-bottom: 20px;
//   }
//   @media (max-width: 990px) {
//     padding-left: 0;
//     padding-right: 0;
//     margin-bottom: -1px;
//     margin-right: -1px;
//     border: 1px solid #f1f1f1;
//   }
//   @media (max-width: 767px) {
//     flex: 0 0 100%;
//     max-width: 100%;
//     padding-left: 7.5px;
//     padding-right: 7.5px;
//     margin-bottom: 20px;
//   }
// `;
// const ProductCardWrapper = styled("div")`
//   height: 100%;
//   > div {
//     height: 100%;
//   }
// `;
